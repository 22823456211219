import { Controller } from "stimulus"
import { ajax } from "../lib/request"
import template from "../lib/template"
import * as Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["template", "container", "parent", "input", "submit"]

  connect() {
    this.attachments = []
    this.initialChildren = this.hasContainerTarget ? this.containerTarget.children.length : 0
    this.maxAttachments = this.data.has("max") ? parseInt(this.data.get("max"), 10) : 0
  }

  submit(event) {
    Rails.disableElement(this.submitTarget)

    let form = event.currentTarget

    // we can't initialize FormData(form) then delete attachments[] because IE11
    // doesn't support FormData.delete; we thus manually query form input elements
    // and append them to FormData:
    let data = new FormData()

    Array.from(form.querySelectorAll("input, select, textarea")).forEach(input => {
      if ((input.type === "checkbox" || input.type === "radio") && !input.checked) return
      if (input.disabled) return
      if (input.name === "attachments[]") return
      data.append(input.name, input.value)
    })
    for (let attachment of this.attachments) {
      data.append("attachments[]", attachment)
    }

    // use Rails.ajax to send the request (to transparently interact with
    // controllers that already interact with ajax:xyz events, such as modal):
    ajax(form, data, (res) => {
      if (res.response) {
        try {
          let jsonResponse = JSON.parse(res.response)
          if (jsonResponse.recipients && jsonResponse.recipients.length > 0) {
            event.target.parentElement.setAttribute(
              "data-error-message",
              `${jsonResponse.recipients[0]}`
            )
          }
        } catch (e) {}
      }
      Rails.enableElement(this.submitTarget)
    })
    event.preventDefault()
  }

  add(event) {
    this.attachments = this.attachments.concat(Array.from(event.currentTarget.files))
    if (this.maxAttachments) {
      this.attachments = this.attachments.slice(0, this.maxAttachments)
    }
    this._render()
  }

  remove(event) {
    const index = event.currentTarget.dataset.index
    this.attachments.splice(index, 1)
    this._render()
    event.preventDefault()
  }

  getFileName(event) {
    let fileNameSpan = document.querySelector(".file-name")
    if (event.target.files.length > 0) {
      const fileName = event.target.files[0].name
      fileNameSpan.textContent = fileName
    } else {
      fileNameSpan.textContent = "Aucun fichier"
    }
  }

  triggerFile(event) {
    this.inputTarget.click()
  }

  _render() {
    this.inputTarget.disabled = this.maxAttachments && this.attachments.length >= this.maxAttachments
    if (this.hasParentTarget) this.parentTarget.style.display = "none"

    Array
      .from(this.containerTarget.querySelectorAll("li"))
      .forEach((li, index) => {
        if (index >= this.initialChildren) {
          li.remove()
        }
      })

    this.attachments.forEach((attachment, index) => {
      if (this.hasParentTarget) this.parentTarget.style.display = "block"
      this.containerTarget.appendChild(this._renderTemplate(index, attachment))
    })
  }

  _renderTemplate(index, attachment) {
    const node = template.clone(this.templateTarget)

    const a = node.querySelector("a")
    a.dataset.index = index

    const span = node.querySelector("span")
    if (span) {
      span.textContent = attachment.name
    }

    const img = node.querySelector("img")
    if (img) {
      const reader = new FileReader()
      if (attachment.type === "application/pdf") {
        reader.onload = e => {
          img.parentElement.classList.add("pdf-backgoround")
          img.remove()
        }
      } else if (attachment.type === "image/tiff") {
        reader.onload = e => {
          img.parentElement.classList.add("tiff-backgoround")
          img.remove()
        }
      } else {
        reader.onload = e => { img.src = e.target.result }
      }
      reader.readAsDataURL(attachment)
    }
    return node
  }
}
