import { Controller } from "stimulus"
import { post } from "../lib/request"
import swal from "sweetalert"
import { getImage, cleanSelect } from "../core"
import GtmController from "./gtm_controller.js"
import { createSelect } from "./select_newstyle_controller"

export default class extends Controller {
  connect() {}

  /** Add monument to comparator from checkbox or button(inside monument modal) */
  addTocomparator(event) {
    const ref = event.currentTarget.getAttribute("data-reference")
    const checkboxElement = document.querySelector("#checkbox-comparator-" + ref)
    const fromModalProductPreviw = event.currentTarget.getAttribute("data-from-modal-preview") || false
    // Alpin variables
    const monuments = Alpine.store("catalogs").products
    const isBicolorProducts = Alpine.store("catalogs").isBicolorProducts
    let monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    let monumentsVisible = Alpine.store("catalogs").productsVisible
    let comparatorPopup = Alpine.store("catalogs").comparatorPopup
    const comparatorColor = Alpine.store("catalogs").comparatorColor
    // END Alpin variables
    let isExist = true
    if (event.target.checked === undefined) {
      isExist = !!monumentsToCompare.find(m => m.reference === ref)
    }

    if (!isExist || event.target.checked) {
      if (monumentsToCompare.length === 6) {
        if (event.target.checked) {
          event.target.checked = false
        }
        const errorMsg = event.currentTarget.getAttribute("data-error-msg")
        // alert max 6
        swal({
          title: errorMsg,
          icon: "warning",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "text-cta buttonModal secondary1",
              closeModal: true
            }
          },
          timer: 2000,
        })
        return 0
      }
      let monument
      if (monumentsVisible.find(m => m.reference === ref)) {
        monument = JSON.stringify(monumentsVisible.find(m => m.reference === ref))
      } else if (isBicolorProducts.find(m => m.reference === ref)) {
        monument = JSON.stringify(isBicolorProducts.find(m => m.reference === ref))
      } else {
        monument = JSON.stringify(monuments.find(m => m.reference === ref))
      }
      monumentsToCompare.push(JSON.parse(monument))
      comparatorPopup = true
      if (event.target.checked === undefined) {
        if (checkboxElement) checkboxElement.checked = true
      }
      Alpine.store("catalogs").monumentsToCompare = monumentsToCompare
      // GTM event
      if (!fromModalProductPreviw) {
        GtmController.addAndRemoveFromComparator(true, false, JSON.parse(monument))
      }
      // END GTM event
      // get Prices
      if (comparatorColor !== null && comparatorColor !== 0) {
        const showPrices = !!event.currentTarget.getAttribute("data-item-price-param")
        if (showPrices) {
          getPricesMinMax(comparatorColor)
        }
      }
    } else {
      const product = JSON.parse(JSON.stringify(monumentsToCompare.find(m => m.reference === ref))) // for gtm product object
      monumentsToCompare = monumentsToCompare.filter(m => m.reference !== ref)
      if (monumentsToCompare.length === 0) {
        comparatorPopup = false
      }
      if (event.target.checked === undefined) {
        checkboxElement.checked = false
      }
      Alpine.store("catalogs").monumentsToCompare = monumentsToCompare
      // GTM event
      if (!fromModalProductPreviw) {
        GtmController.addAndRemoveFromComparator(false, false, product)
      }
      // END GTM event
    }
    this.setMonumentRefOnLocalstorage(monumentsToCompare)
    // disable layout filter
    disableLayoutSelect("comparatorlayout")
    Alpine.store("catalogs").comparatorPopup = comparatorPopup
  }

  /** Remove monument from comparator */
  async rmFromcomparator(event) {
    const ref = event.currentTarget.getAttribute("data-reference")
    // Alpin variables
    let monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    let comparatorPopup = Alpine.store("catalogs").comparatorPopup
    // END Alpin variables
    if (ref !== "all") {
      const product = monumentsToCompare.find(m => m.reference === ref) // for gtm product object
      monumentsToCompare = monumentsToCompare.filter(m => m.reference !== ref)
      const checkboxElement = document.querySelector("#checkbox-comparator-" + ref)
      checkboxElement.checked = false
      Alpine.store("catalogs").monumentsToCompare = await monumentsToCompare
      // GTM event
      GtmController.addAndRemoveFromComparator(false, true, product)
      // END GTM event
    } else {
      for (const monument of monumentsToCompare) {
        const checkboxElement = document.querySelector("#checkbox-comparator-" + monument.reference)
        checkboxElement.checked = false
      }
      monumentsToCompare = []
      Alpine.store("catalogs").monumentsToCompare = []
      // GTM event
      GtmController.comparatorRemoveAllOrCompare(event.currentTarget.getAttribute("data-gtm-tkey"))
      // END GTM event
    }
    if (monumentsToCompare.length === 0) {
      comparatorPopup = false
    }
    this.setMonumentRefOnLocalstorage(monumentsToCompare)
    // disable layout filter
    disableLayoutSelect("comparatorlayout")

    Alpine.store("catalogs").comparatorPopup = comparatorPopup
  }

  /** Show comparator and hide it with animation */
  showComparatorPopup() {
    let comparatorPopupHead = Alpine.store("catalogs").comparatorPopupHead
    comparatorPopupHead = !comparatorPopupHead
    Alpine.store("catalogs").comparatorPopupHead = comparatorPopupHead
    const comparatorPopupElement = document.querySelector(".comparator-popup")
    let offsetTop = comparatorPopupElement.offsetTop
    if (comparatorPopupHead) {
      let refreshIntervalId1 = setInterval(() => {
        if (offsetTop <= document.documentElement.scrollHeight - 42) {
          offsetTop += 12
          comparatorPopupElement.style.top = offsetTop + "px"
        } else {
          comparatorPopupElement.style.top = document.documentElement.scrollHeight - 42 + "px"
          clearInterval(refreshIntervalId1)
        }
      }, 1)
    } else {
      comparatorPopupElement.style.bottom = "unset"
      let refreshIntervalId2 = setInterval(() => {
        if (offsetTop >= document.documentElement.scrollHeight - comparatorPopupElement.scrollHeight) {
          offsetTop -= 12
          comparatorPopupElement.style.top = offsetTop + "px"
        } else {
          comparatorPopupElement.style.bottom = 0
          comparatorPopupElement.style.top = "unset"
          clearInterval(refreshIntervalId2)
        }
      }, 1)
    }

    // GTM event
    GtmController.comparatorToggle()
    // END GTM event
  }

  /** Set references of monuments in LocalStorage */
  setMonumentRefOnLocalstorage(monuments) {
    const organization = Alpine.store("catalogs").organization.clientId
    let comparatorRefs = JSON.parse(localStorage.getItem("comparatorRefs")) || []
    if (comparatorRefs.find(obj => obj.organization === organization)) {
      comparatorRefs.forEach(obj => {
        if (obj.organization === organization) {
          obj.monuments = monuments.map(m => m.reference)
        }
      })
    } else {
      comparatorRefs.push({ organization: organization, monuments: monuments.map(m => m.reference) })
    }
    localStorage.setItem("comparatorRefs", JSON.stringify(comparatorRefs))
  }

  /** On click "Comparer", init all monuments with color of the first monument in comparator  */
  async initComparatorColor(event) {
    // END reset layout
    // GTM event
    GtmController.comparatorRemoveAllOrCompare(event.currentTarget.getAttribute("data-gtm-tkey"), "comparateur")
    // END GTM event
    // Alpin variables
    let monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    let comparatorColor = Alpine.store("catalogs").comparatorColor
    let catalogColor = Alpine.store("catalogs").color
    // END Alpin variables

    if (comparatorColor === catalogColor) {
      return 0
    }
    // Reset layout select
    Alpine.store("catalogs").comparatorLayout = null
    // Init color filter
    Alpine.store("catalogs").comparatorColor = 0
    // init Prices
    Alpine.store("catalogs").monumentsToCompare = this.initPrices(monumentsToCompare)
  }

  /** Filter with color inside comparator modal  */
  async onClickColorComparator(event) {
    const color = event.currentTarget.getAttribute("data-color") || null
    // Alpin variables
    let monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
    let oldmonumentsVisible = Alpine.store("catalogs").oldproductsVisible
    // END Alpin variables

    document.querySelectorAll(".comparator-modal .product-image")?.forEach(
      image => {
        image.loading = "lazy"
        image.classList.add("loading")
      }
    )
    if (color === null || color === 0) {
      // Hide layout filter
      Alpine.store("catalogs").comparatorLayoutDisplay = false
      // Reset layout
      Alpine.store("catalogs").comparatorLayout = null
      // Reset color
      Alpine.store("catalogs").comparatorColor = 0
      let monuments = JSON.parse(JSON.stringify(monumentsToCompare))
      Alpine.store("catalogs").monumentsToCompare = []
      await monuments.forEach(async product => {
        const oldMonument = oldmonumentsVisible.find(mnt => mnt.reference === product.reference)
        product.image = oldMonument.image
        product.fullimage = oldMonument.fullimage
        product.gm = oldMonument.gm
        product.hide = oldMonument.hide
        product.price = null
      })
      Alpine.store("catalogs").monumentsToCompare = monuments
      // GTM event
      GtmController.displayComparatorModal()
      // END GTM event
    } else {
      if (!Alpine.store("catalogs").comparatorLayoutDisplay) {
        displayLayout().then(async () => {
          // create layout select for modal comparator filter
          await createSelect()
          await disableLayoutSelect("comparatorlayout")
          // END select layout
        })
      }

      Alpine.store("catalogs").comparatorColor = parseInt(color, 10)
      let monuments = [...monumentsToCompare]
      Alpine.store("catalogs").monumentsToCompare = []
      const res = await this.setColorOnMonument(monuments, parseInt(color, 10))
      Alpine.store("catalogs").monumentsToCompare = res

      // get Prices
      const showPrices = !!event.currentTarget.getAttribute("data-item-price-param")
      if (showPrices) {
        await getPricesMinMax(color)
      }

      // GTM event
      GtmController.displayComparatorModal()
      // END GTM event
    }
  }

  /** */
  setColorOnMonument(monumentsToCompare, color) {
    // Alpin variables
    const granites = Alpine.store("catalogs").granites
    // END Alpin variables
    monumentsToCompare.forEach(async product => {
      const monumentGranit = Alpine.store("catalogs").monuments_granits.find(g => g.token === product.granits)
      const colorGranit = product.granits && monumentGranit.granits.length !== 0 ? granites.filter(g1 => g1.color === color && monumentGranit.granits.find(g2 => g2.gm === g1.reference)) : []
      if (colorGranit.length !== 0) {
        // select random available granite
        let granit = colorGranit[Math.floor(Math.random() * colorGranit.length)]
        let img = await getImage(granit.reference, product, "large")
        product.image = img.image
        product.fullimage = img.image
        product.gm = granit.reference
        product.hide = false
      } else {
        product.hide = true
      }
    })

    return monumentsToCompare
  }

  /** */
  initPrices(monumentsToCompare) {
    monumentsToCompare.forEach(m => {
      m.hide = false
      m.price = null
    })
    return monumentsToCompare
  }
}

/** Call api, to get prices(min, max) of comparator monuments  */
async function getPricesMinMax(color) {
  // Alpin variables
  let monumentsToCompare = Alpine.store("catalogs").monumentsToCompare
  const layout = Alpine.store("catalogs").comparatorLayout
  // END Alpin variables
  Alpine.store("catalogs").comparatorLoadPrice = true
  let formData = new FormData()
  const monuments = monumentsToCompare.map(m =>
    ({
      reference: m.originalMonument ? m.originalMonument.reference : m.reference,
      preconfId: m.preconfId
    })
  )
  formData.append("monuments", JSON.stringify(monuments))
  formData.append("color", color)
  formData.append("layout", layout === "" ? null : layout)
  await post("/comparator/price", formData).then(response => {
    return response.json()
      .then(async data => {
        await Object.entries(data.prices).forEach(entry => {
          const [key, value] = entry
          monumentsToCompare.forEach(m => {
            const mRef = m.originalMonument ? m.originalMonument.reference : m.reference
            if (mRef === key) {
              m.price = value
              m.hide = value.min === null && value.max === null
              return 0
            }
          })
        })
        Alpine.store("catalogs").comparatorLoadPrice = false
      })
  })
}

/** Check if monuments keys are mixed (Inhumation x Cinéraires) */
export function checkMonumentsType() {
  // Alpin variables
  const monumentsToCompare = [...Alpine.store("catalogs").monumentsToCompare]
  const keywordslist = Alpine.store("catalogs").allkeywords
  // END Alpin variables
  let isInhumation = false
  let isCineraire = false
  monumentsToCompare.forEach(monument => {

    const keywords = monument.keys.map(k => keywordslist.find(kw => kw.id === k)?.name?.trim())

    if (keywords.includes("Inhumation")) {
      isInhumation = true
    } else if (keywords.includes("Cinéraires")) {
      isCineraire = true
    }
  })

  if (isInhumation && isCineraire) {
    return true
  }
  return false
}

export function disableLayoutSelect(name) {
  const aux = checkMonumentsType()
  // select elemennts
  const selectContainerElement = document.querySelector(`[data-mate-select='active'][name='${name}']`)
  if (selectContainerElement) {
    // Layout tooltip
    const tooltipElement = document.querySelector("#filterbar_comparator_layout_tooltip")
    const selectedOptionElement = selectContainerElement.querySelector("[class='selected_option']")
    const iconSelectMateElement = selectContainerElement.querySelector("[class='icon_select_mate']")
    if (aux) { // monuments are mixed => disable layout filter
      // Reset layout
      Alpine.store("catalogs").comparatorLayout = null
      cleanSelect("comparatorlayout")
      // Reset color
      selectedOptionElement.removeAttribute("data-action")
      iconSelectMateElement.removeAttribute("data-action")
      if (tooltipElement) tooltipElement.style.display = "block"
    } else {
      selectedOptionElement.setAttribute("data-action", "click->selectnewstyle#openSelect")
      iconSelectMateElement.setAttribute("data-action", "click->selectnewstyle#openSelect")
      if (tooltipElement) tooltipElement.style.display = "none"
    }
  }
}

/** Set select(layout)  */
export function setLayout(value) {
  Alpine.store("catalogs").comparatorLayout = value === "" ? null : value
  const comparatorColor = Alpine.store("catalogs").comparatorColor
  if (comparatorColor !== null && comparatorColor !== "null" && comparatorColor !== 0) {
    getPricesMinMax(comparatorColor)
  }
}

// affectation promise
function displayLayout() {
  return new Promise((resolve, reject) => {
    Alpine.store("catalogs").comparatorLayoutDisplay = true
    if (Alpine.store("catalogs").comparatorLayoutDisplay) {
      resolve()
    }
  })
}
