import { Controller } from "stimulus"
import * as Rails from "@rails/ujs"
import * as Turbolinks from "turbolinks"
import { get } from "../lib/request"

export default class extends Controller {
  static targets = ["form", "content"]

  show(event) {
    get(event.currentTarget.value)
      .then(response => {
        return response.text()
          .then(html => this.contentTarget.innerHTML = html)
      })
  }

  visit(event) {
    let urlTab
    let urlisFavorite
    if (event.currentTarget.action) {
      urlTab = event.currentTarget.action.split("/")
      urlisFavorite = event.currentTarget.action.includes("expos/monuments") && urlTab[urlTab.length - 1].includes("favorite")
    }
    if (urlisFavorite) {
      const buttonStar = event.currentTarget.button.getElementsByClassName("favorite_expos")[0]
      if (buttonStar.className.includes("active")) {
        buttonStar.classList.remove("active")
      } else {
        buttonStar.classList.add("active")
      }
    }

    let form = this.hasFormTarget ? this.formTarget : this.element

    if (form.dataset.remote === "true") {
      Rails.handleRemote.call(form, event)
    } else if (form.method === "get") {
      // don't submit the form twice:
      if (!this.isLoading) {
        this.isLoading = true
        this._turbolinkVisit(form)
      }
    } else {
      form.submit()
      return
    }

    if (event.type === "submit") {
      // don't reload page (turbolinks will fetch it):
      event.preventDefault()
    }
  }

  _turbolinkVisit(form) {
    // notify outer controller(s) that we're visiting an URL:
    const evt = document.createEvent("CustomEvent")
    evt.initCustomEvent("autosubmit-visit", true, true, null)
    this.element.dispatchEvent(evt)

    // extract form params (skipping empty values):
    let params = []
    Array
      .from(this.element.querySelectorAll("input:not([name=utf8]):not([disabled]), select:not([disabled])"))
      .forEach(input => {
        if (input.value || input.dataset.keep === "true") {
          params.push(encodeURIComponent(input.name) + "=" + encodeURIComponent(input.value))
        }
      })

    Turbolinks.visit(form.action + "?" + params.join("&"))
  }
};
